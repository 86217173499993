<template>
  <div style="width: 100%;" @click="handleClear">
    <el-button
      class="createBtn"
      :disabled="!this.canWrite(additionalValidateRoute)"
      @click.stop="createContentItem"
      >{{ __("Add Phone Number") }}
    </el-button>

    <div style="padding: 0 36px;margin-top: 165px;">
      <el-form class="form-container">
        <PaginationToolbar
          :sort-by-options="sortByOptions"
          :content-api="contentAPI"
          :search-fields="searchFieldsString"
          :adv-search-prefill="searchQueryString"
          :search-join="searchJoinMethod"
          :show-slot="true"
          @success="$emit('replaceContent', $event)"
        >
          <el-button
            class="bulkMoveBtn"
            slot="advancedSearch"
            @click="showAdvancedSearch = true"
          >
            <img
              :src="getAdvancedSearchImage"
              class="slotImg"
              onload="SVGInject(this)"
            />
          </el-button>
          <!-- bulk move -->
          <el-button
            class="bulkMoveBtn"
            v-show="this.canWrite('phone_numbers')"
            @click="handleBulkMove"
            slot="buttonGroup"
            ><img
              :src="getBulkUploadImage"
              class="slotImg"
              onload="SVGInject(this)"
            />
          </el-button>
          <!-- multi select actions -->
          <template v-if="this.multiSelectHasItem">
            <el-col :span="4" slot="buttonGroup">
              <el-select
                class="bulkMoveBtn"
                default-first-option
                filterable
                v-model="action"
                :placeholder="__('Select Action For')"
                @change="handleActionChange"
              >
                <el-option
                  v-for="option in multiSelectActions"
                  :key="option.value"
                  :label="option.label"
                  :value="option.value"
                >
                  <i :class="`${option.icon} table-actions`"></i>
                  {{ option.label }}
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="4" slot="buttonGroup">
              <span
                style="margin: 10px 18px;align-items: initial;display: inline-block;"
              >
                {{
                  __(":selectednumbercount number(s)", {
                    selectednumbercount: this.selectedNumbersCount
                  })
                }}
              </span>
            </el-col>
          </template>
        </PaginationToolbar>
      </el-form>
      <bulk-move
        v-if="phoneNumbers.length"
        file-url=""
        :open-modal="isBulkMode"
        @cancel="handleBulkMove"
      ></bulk-move>
      <!-- Advanced Search Dialog -->
      <el-dialog
        :title="__('Advanced Search')"
        :visible.sync="showAdvancedSearch"
        width="30%"
        destroy-on-close
      >
        <div>
          <el-scrollbar :native="false">
            <el-form
              ref="advSearchForm"
              v-model="searchOptions"
              label-position="top"
              size="large"
              hide-required-asterisk
            >
              <el-row type="flex">
                <el-col :span="8">
                  <el-form-item
                    :label="__('Starts With')"
                    prop="searchOptions.phone_number.starts_with"
                  >
                    <el-input
                      class="advanceSearchInput"
                      v-model="searchOptions.phone_number.starts_with"
                      :placeholder="__('Starts with')"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="8" style="margin-left: 20px">
                  <el-form-item
                    :label="__('Ends With')"
                    prop="searchOptions.phone_number.ends_with"
                  >
                    <el-input
                      class="advanceSearchInput"
                      v-model="searchOptions.phone_number.ends_with"
                      :placeholder="__('Ends with')"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex">
                <el-col :span="16">
                  <el-form-item
                    :label="__('Status')"
                    prop="searchOptions.is_enabled"
                  >
                    <el-select
                      filterable
                      v-model="searchOptions.is_enabled"
                      default-first-option
                    >
                      <el-option
                        v-for="item in statusOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex">
                <el-col :span="16">
                  <el-form-item :label="__('Usage')" prop="searchOptions.usage">
                    <el-select
                      filterable
                      v-model="searchOptions.usage"
                      default-first-option
                    >
                      <el-option
                        v-for="item in usageOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex">
                <el-col :span="16">
                  <el-form-item :label="__('Tags')" prop="searchOptions.tags">
                    <el-input
                      type="textarea"
                      class="advanceSearchTextArea"
                      v-model="searchOptions.tags"
                      :placeholder="
                        __('Enter multiple tags for ex: prod,demo,...etc.')
                      "
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex">
                <el-col :span="16">
                  <el-form-item
                    :label="__('Account')"
                    prop="searchOptions.ac_id"
                  >
                    <el-select
                      default-first-option
                      filterable
                      v-model="searchOptions.ac_id"
                      clearable
                      placeholder="Select"
                    >
                      <el-option
                        v-for="(item, index) in accounts(
                          selectedServiceProviderId
                        )"
                        :key="index"
                        :label="item.label"
                        :value="item.value.ac_id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex">
                <el-col :span="16">
                  <el-form-item
                    :label="__('Channel')"
                    prop="searchOptions.channel"
                  >
                    <el-select
                      filterable
                      v-model="searchOptions.channel"
                      default-first-option
                    >
                      <el-option
                        v-for="item in channelOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex">
                <el-col :span="4">
                  <el-form-item>
                    <el-button
                      @click="handleAdvSearchClick"
                      class="submitBtn"
                      v-show="this.canWrite('phone_numbers')"
                      >{{ __("Search") }}
                    </el-button>
                  </el-form-item>
                </el-col>
                <el-col :span="4" style="margin-left: 10px">
                  <el-form-item>
                    <el-button @click="handleResetClick" class="resetBtn">{{
                      __("Reset")
                    }}</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-scrollbar>
        </div>
      </el-dialog>
      <!-- Actions Dialog -->
      <el-dialog
        :visible.sync="openAssignModel"
        destroy-on-close
        append-to-body
        :before-close="handleCloseModal"
      >
        <el-form>
          <div v-show="this.showAc" v-loading="loading">
            <el-row type="flex">
              <el-col :span="20">
                <el-form-item :label="__('Account')" prop="ac_id">
                  <el-select
                    filterable
                    value-key="ac_id"
                    v-model="assign_ac_id"
                    default-first-option
                    placeholder="Select"
                    style="width: 100%"
                    @change="handleAcAssignment"
                  >
                    <el-option
                      v-for="(item, index) in accounts(
                        selectedServiceProviderId
                      )"
                      :key="index"
                      :label="item.label"
                      :value="item.value.ac_id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item style="padding-top: 28px">
                  <el-button
                    style="margin-left: 15px;"
                    type="primary"
                    @click="assignAccount"
                    class="submitBtn"
                    v-show="this.canWrite('phone_numbers')"
                    >{{ __("assign") }}
                  </el-button>
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item style="padding-top: 28px">
                  <el-button
                    type="primary"
                    @click="unAssignAccount"
                    class="submitBtn"
                    v-show="this.canWrite('phone_numbers')"
                    >{{ __("unassign") }}
                  </el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div v-show="this.showTag" v-loading="loading">
            <el-row type="flex">
              <el-col>
                <el-form-item class="form_label_top" :label="__('Add Tags')">
                  <el-tag
                    :key="tag"
                    v-for="tag in getTagsArray(this.tags)"
                    closable
                    :disable-transitions="false"
                    @close="handleDeleteTag(tag)"
                  >
                    {{ tag }}
                  </el-tag>
                  <el-input
                    class="input-new-tag"
                    style="margin-right: 20px"
                    v-if="inputVisible"
                    v-model="inputValue"
                    ref="saveTagInput"
                    @keyup.enter.native="handleInputConfirm"
                    @blur="handleInputConfirm"
                  >
                  </el-input>
                  <el-button
                    v-else
                    class="button-new-tag"
                    @click="showInput"
                    id="add_tag_btn"
                  >
                    {{ __("+ New Tag") }}</el-button
                  >
                  <el-button type="primary" @click="addTags">
                    {{ __("Save") }}</el-button
                  >
                </el-form-item>
                <el-form-item class="form_label_top" :label="__('Remove Tags')">
                  <el-tag
                    :key="tag"
                    v-for="tag in getTagsArray(this.tagsToRemove)"
                    closable
                    :disable-transitions="false"
                    @close="handleDeleteRemoveTag(tag)"
                  >
                    {{ tag }}
                  </el-tag>
                  <el-input
                    class="input-new-tag"
                    style="margin-right: 20px"
                    v-if="removeVisible"
                    v-model="removeValue"
                    ref="removeTagInput"
                    @keyup.enter.native="handleRemoveInputConfirm"
                    @blur="handleRemoveInputConfirm"
                  >
                  </el-input>
                  <el-button
                    v-else
                    class="button-new-tag"
                    @click="showRemoveInput"
                    id="remove_tag_btn"
                  >
                    {{ __("- Remove Tag") }}</el-button
                  >
                  <el-button
                    style="margin-left: 10px"
                    type="primary"
                    @click="removeTags"
                  >
                    {{ __("Save") }}</el-button
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div v-show="this.showEnableOrDisableOrDelete" v-loading="loading">
            <el-row type="flex">
              <el-col :span="20">
                <h2>
                  {{
                    __(":enableordisable Numbers", {
                      enableordisable: this.getActionLabel()
                    })
                  }}
                </h2>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="20">
                <el-form-item>
                  <span>
                    <!-- eslint-disable-next-line -->
                    {{ __("This will :action the following given numbers. Continue?", {
                          action: this.action
                        }
                      )
                    }}
                  </span>
                  <el-scrollbar class="dialog-data" :native="false">
                    <div
                      v-for="number in getSelectedPhoneNumbers()"
                      :key="number"
                      class="text item"
                    >
                      {{ number }}
                    </div>
                  </el-scrollbar>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="3">
                <el-form-item style="padding-top: 10px">
                  <el-button
                    type="primary"
                    @click="handleEnableOrDisableOrDeleteNumber(action)"
                    class="submitBtn"
                    v-show="this.canWrite('phone_numbers')"
                  >
                    {{ this.getActionLabel() }}
                  </el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </el-dialog>
      <el-scrollbar :native="true">
        <div class="contentListContainer">
          <el-table
            ref="phoneNumbersTable"
            v-loading="loading"
            :data="phoneNumbers"
            @row-click="handleSingleClick"
            @row-dblclick="handleDoubleClick"
            @selection-change="handleSelectionChange"
            highlight-current-row
            v-show="this.canRead('phone_numbers')"
            class="list-table"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column :label="__('Phone number')">
              <template slot-scope="scope">
                <span>{{ scope.row.phone_number }}</span>
              </template>
            </el-table-column>

            <el-table-column
              :label="__('SP ID')"
              v-if="selectedServiceProviderId === 'all'"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.sp_id }}</span>
              </template>
            </el-table-column>

            <el-table-column :label="__('Account')">
              <template slot-scope="scope">
                <span>{{ scope.row.ac_name }}</span>
              </template>
            </el-table-column>

            <el-table-column :label="__('Task')">
              <template slot-scope="scope">
                <span>{{ scope.row.task_name }}</span>
              </template>
            </el-table-column>

            <el-table-column :label="__('Task Type')">
              <template slot-scope="scope">
                <span
                  v-if="
                    scope.row.channel === 'sms_nexmo' ||
                      scope.row.channel === 'sms_twilio'
                  "
                >
                  {{ __("sms") }}
                </span>
                <span v-else> {{ scope.row.channel }}</span>
              </template>
            </el-table-column>

            <el-table-column :label="__('Tags')">
              <template slot-scope="scope" v-if="scope.row.tags">
                <el-tag
                  v-for="tag in tagsArray(scope.row.tags)"
                  :key="tag"
                  style="padding: 0 3px 3px 3px;margin-left: 3px;"
                >
                  {{ tag }}
                </el-tag>
              </template>
            </el-table-column>

            <el-table-column :label="__('Status')">
              <template slot-scope="scope">
                <el-tag type="danger" v-if="!scope.row.is_enabled">
                  {{ __("DISABLED") }}
                </el-tag>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import BulkMove from "./BulkMove";
import _ from "lodash";
import { EventBus } from "@/EventBus";

export default {
  name: "TelephonyPhoneNumbersManageList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    BulkMove
  },
  data() {
    return {
      additionalValidateRoute: "phone-numbers",
      sortByColumns: ["phone_number", "phone_number_id"],
      multiSelectActions: [
        {
          value: "enable",
          label: __("Enable"),
          icon: "el-icon-open",
          permission: "write"
        },
        {
          value: "disable",
          label: __("Disable"),
          icon: "el-icon-turn-off",
          permission: "write"
        },
        {
          value: "tags",
          label: __("Tags"),
          icon: "el-icon-collection-tag",
          permission: "write"
        },
        {
          value: "delete",
          label: __("Delete"),
          icon: "el-icon-delete",
          permission: "write"
        },
        {
          value: "assign",
          label: __("Assign Account"),
          icon: "el-icon-user",
          permission: "write"
        }
      ],
      statusOptions: [
        { value: "all", label: __("All") },
        { value: "1", label: __("Enabled") },
        { value: "0", label: __("Disabled") }
      ],
      usageOptions: [
        { value: "all", label: __("All") },
        { value: "mapped", label: __("Mapped") },
        { value: "un-mapped", label: __("Unmapped") },
        { value: "un-assigned", label: __("Unassigned") }
      ],
      channelOptions: [
        { value: "all", label: __("All") },
        { value: "voice", label: __("Voice") },
        { value: "sms_twilio", label: "SMS Twilio" },
        { value: "sms_nexmo", label: "SMS Nexmo" },
        { value: "sms_wavecell", label: "SMS Wavecell" },
        { value: "whatsapp_twilio", label: "Whatsapp Twilio" },
        { value: "whatsapp_sinch", label: "Whatsapp Sinch" },
        { value: "whatsapp_360dialog", label: "Whatsapp 360" }
      ],
      action: "",
      isBulkMode: false,
      showSlot: true,
      openAssignModel: false,
      showTag: false,
      showAc: false,
      showEnableOrDisableOrDelete: false,
      showAdvancedSearch: false,
      searchFieldsString: "",
      searchOptions: {},
      searchQueryString: "",
      searchJoinMethod: "",
      multipleSelection: [],
      assign_ac_id: "",
      inputValue: "",
      inputVisible: false,
      removeValue: "",
      removeVisible: false,
      tags: "",
      tagsToRemove: ""
    };
  },

  created() {
    this.initializeSearchOptions();
  },
  computed: {
    ...mapState("phonenumbers", {
      phoneNumbers: state => state.phoneNumbers,
      loading: state => state.loading
    }),

    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId
    }),

    ...mapGetters("serviceproviders", {
      accounts: "accounts"
    }),

    getBulkUploadImage() {
      return require("@/assets/icons/move-phone-numbers.svg");
    },

    getAdvancedSearchImage() {
      return require("@/assets/icons/preview_table/query_builder_ds.svg");
    },

    multiSelectHasItem() {
      let self = this;
      return self.multipleSelection.length > 0;
    },
    selectedNumbersCount() {
      let self = this;
      return self.multipleSelection.length;
    }
  },

  methods: {
    ...mapActions("phonenumbers", {
      contentAPI: "getPhoneNumbers",
      deleteContentMethod: "deletePhoneNumber",
      undoDeleteContent: "undoDeletePhoneNumber",
      manageMultiplePhoneNumbersAction: "manageMultiplePhoneNumbersAction"
    }),
    getTagsArray(tags) {
      return tags ? tags.split(",") : [];
    },
    handleClear() {
      this.$refs.phoneNumbersTable.setCurrentRow();
      this.handleClearSelection();
    },
    clearAside() {
      this.handleClearAside();
    },
    handleSingleClick(row, column, event) {
      event.stopPropagation();
      if (this.selectedNumbersCount <= 1) {
        this.handleSelect(null, row);
      }
    },
    handleDoubleClick(row, column, event) {
      event.stopPropagation();
      this.handleEdit(null, row);
    },
    handleBulkMove() {
      this.isBulkMode = !this.isBulkMode;
    },
    tagsArray(tagsString) {
      return tagsString.split(",", 2);
    },

    handleCloseModal() {
      this.action = "";
      this.openAssignModel = false;
      this.showAc = false;
      this.showTag = false;
      this.showEnableOrDisableOrDelete = false;
    },

    handleActionChange(action) {
      switch (action) {
        case "tags":
          this.handleTag();
          break;
        case "enable":
        case "disable":
        case "delete":
          this.handleEnableOrDisableorDelete();
          break;
        case "assign":
          this.handleAssignAccount();
          break;
      }
    },
    handleCancel() {
      this.$emit("cancel");
      this.action = "";
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      let ac_ids = _.flatMap(_.map(this.multipleSelection, "ac_id"));
      if (ac_ids.length === 1) {
        this.assign_ac_id = ac_ids[0];
      }
    },
    handleTag() {
      this.openAssignModel = true;
      this.showTag = true;
    },
    handleEnableOrDisableorDelete() {
      this.openAssignModel = true;
      this.showEnableOrDisableOrDelete = true;
    },
    handleAssignAccount() {
      this.openAssignModel = true;
      this.showAc = true;
    },
    handleResetMultiSelectItems() {
      this.openAssignModel = false;
      this.showAc = false;
      this.showTag = false;
      this.showEnableOrDisableOrDelete = false;
      this.multipleSelection = [];
      this.assign_ac_id = "";
      this.inputValue = "";
      this.inputVisible = false;
      this.removeValue = "";
      this.removeVisible = false;
      this.tags = "";
      this.action = "";
      this.tagsToRemove = "";
    },
    handleAcAssignment(val) {
      this.assign_ac_id = val;
    },
    getPhoneNumberIds() {
      return _.flatMap(_.map(this.multipleSelection, "phone_number_id"));
    },
    getSelectedPhoneNumbers() {
      return _.flatMap(_.map(this.multipleSelection, "phone_number"));
    },
    getActionLabel() {
      return _.upperFirst(this.action);
    },
    async handleEnableOrDisableOrDeleteNumber(action) {
      await this.manageMultiplePhoneNumbersAction({
        phone_numbers: this.getPhoneNumberIds(),
        action: action
      })
        .then(data => {
          if (data !== undefined) {
            this.$message({
              name: "success",
              message: data.message
            });
            this.handleResetMultiSelectItems();
            EventBus.$emit("list-changed");
          }
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: err.response.data.message
          });
        });
    },
    async assignAccount() {
      await this.manageMultiplePhoneNumbersAction({
        phone_numbers: this.getPhoneNumberIds(),
        action: "assign_ac",
        ac_id: this.assign_ac_id
      })
        .then(data => {
          if (data !== undefined) {
            this.$message({
              name: "success",
              message: data.message
            });
            this.handleResetMultiSelectItems();
            EventBus.$emit("list-changed");
          }
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: err.response.data.message
          });
        });
    },
    async unAssignAccount() {
      await this.manageMultiplePhoneNumbersAction({
        phone_numbers: this.getPhoneNumberIds(),
        action: "unassign_ac",
        ac_id: null
      })
        .then(data => {
          if (data !== undefined) {
            this.$message({
              name: "success",
              message: data.message
            });
            this.handleResetMultiSelectItems();
            EventBus.$emit("list-changed");
          }
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: err.response.data.message
          });
        });
    },
    async addTags() {
      await this.manageMultiplePhoneNumbersAction({
        phone_numbers: this.getPhoneNumberIds(),
        action: "add_tags",
        tags: this.tags
      })
        .then(data => {
          if (data !== undefined) {
            this.$message({
              name: "success",
              message: data.message
            });
            this.handleResetMultiSelectItems();
            EventBus.$emit("list-changed");
          }
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: err.response.data.message
          });
        });
    },
    async removeTags() {
      await this.manageMultiplePhoneNumbersAction({
        phone_numbers: this.getPhoneNumberIds(),
        action: "remove_tags",
        tags: this.tagsToRemove
      })
        .then(data => {
          if (data !== undefined) {
            this.$message({
              name: "success",
              message: data.message
            });
            this.handleResetMultiSelectItems();
            EventBus.$emit("list-changed");
          }
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: err.response.data.message
          });
        });
    },
    handleDeleteTag(tag) {
      let tagsArray = _.split(this.tags, ",");
      const tagIndex = _.findIndex(tagsArray, tagA => {
        return tagA === tag;
      });
      tagsArray.splice(tagIndex, 1);
      this.tags = tagsArray.join(",");
    },
    handleDeleteRemoveTag(tag) {
      let tagsArray = _.split(this.tagsToRemove, ",");
      const tagIndex = _.findIndex(tagsArray, tagA => {
        return tagA === tag;
      });
      tagsArray.splice(tagIndex, 1);
      this.tagsToRemove = tagsArray.join(",");
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    showRemoveInput() {
      this.removeVisible = true;
      this.$nextTick(() => {
        this.$refs.removeTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        if (this.tags) {
          this.tags += "," + inputValue;
        } else {
          this.tags += inputValue;
        }
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    handleRemoveInputConfirm() {
      let inputValue = this.removeValue;
      if (inputValue) {
        if (this.tagsToRemove) {
          this.tagsToRemove += "," + inputValue;
        } else {
          this.tagsToRemove += inputValue;
        }
      }
      this.removeVisible = false;
      this.removeValue = "";
    },
    initializeSearchOptions() {
      this.$set(this.searchOptions, "phone_number", {});
      this.$set(this.searchOptions, "phone_number", {
        starts_with: "",
        ends_with: ""
      });
      this.$set(this.searchOptions, "is_enabled", "");
      this.$set(this.searchOptions, "usage", "");
      this.$set(this.searchOptions, "channel", "");
      this.$set(this.searchOptions, "tags", "");
      this.$set(this.searchOptions, "ac_id", "");
      this.$set(this.searchOptions, "task_id", "");
    },
    buildSearchFields() {
      let equalConditionQualifiers = [
        "ac_id",
        "sp_id",
        "task_id",
        "is_enabled"
      ];
      let searchFields = _.flatMap(this.searchOptions, (value, key) => {
        if (typeof value === "object") {
          let object_properties = _.keys(value);
          return _.map(object_properties, obj_key => {
            return `${key}~${obj_key}`;
          });
        }
        if (equalConditionQualifiers.includes(key)) {
          key = `${key}:=`;
        }
        return key;
      });
      this.searchFieldsString = searchFields.join(";");
      this.searchJoinMethod = "and";
    },
    async handleAdvSearchClick() {
      let self = this;
      await this.buildSearchFields();
      let searchQueries = _.compact(
        _.flatMap(self.searchOptions, (value, key) => {
          if (typeof value === "object") {
            return _.flatMap(value, (obj_val, obj_key) => {
              if (obj_val !== "") {
                return `${key}~${obj_key}:${obj_val}`;
              }
            });
          } else if (value !== "") {
            return `${key}:${value}`;
          }
        })
      );
      this.searchQueryString = searchQueries.join(";");
      this.showAdvancedSearch = false;
      this.handleRefreshData();
    },
    handleResetClick() {
      this.searchQueryString = "";
      this.searchFieldsString = "";
      this.searchJoinMethod = "";
      this.initializeSearchOptions();
      this.handleRefreshData();
    },
    handleRefreshData() {
      this.contentAPI().then(data => {
        EventBus.$emit("list-changed", data.data);
      });
    }
  },
  watch: {
    selectedItemIndex: {
      immediate: true,
      handler: function(val) {
        if (val !== -1 && val !== null) {
          this.$refs.phoneNumbersTable &&
            this.$refs.phoneNumbersTable.setCurrentRow(this.phoneNumbers[val]);
        }
      }
    },
    selectedNumbersCount: {
      immediate: true,
      handler: function(val) {
        if (val > 1) {
          this.handleClear();
          this.clearAside();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$--color-node-hover-default: var(--theme-hover-color) !default;
$--color-white: #ffffff !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/button.scss";
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/content-list.scss";
@import "~@/styles/tags.scss";

.bulkMoveBtn {
  margin-left: 10px;
  height: 44px;
  width: 30px;
  border-color: #f5f5f8;
  color: #a0a8b5;
  &:hover {
    background-color: transparent;
    border-color: $content-theme-color;
    ::v-deep svg {
      fill: $content-theme-hover-color;
      path {
        fill: $content-theme-hover-color;
      }
    }
  }
  .slotImg {
    width: 24px;
    margin-left: -10px !important;
  }
  &.is-disabled:hover,
  &.is-disabled {
    color: #a0a8b5;
    border-color: #f5f5f8;
    ::v-deep svg {
      fill: #a0a8b5;
      path {
        fill: #a0a8b5;
      }
    }
  }
}
.bulkImg {
  width: 24px;
  margin-left: -10px;
}
.table-actions {
  font-weight: 600;
  font-size: larger;
  margin-left: -5px;
  margin-right: 5px;
}
.text {
  font-size: 14px;
}
.item {
  padding: 2px 0;
}

.dialog-data {
  height: calc(20vh - 10px);
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
</style>
<style lang="scss">
.advanceSearchInput {
  .el-input.is-active .el-input__inner,
  .el-input__inner:focus {
    border-color: var(--theme-color) !important;
  }
}
.advanceSearchTextArea {
  .el-textarea__inner:focus {
    border-color: var(--theme-color) !important;
  }
}
</style>
