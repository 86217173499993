<template>
  <div>
    <i
      v-if="isWarning"
      class="el-icon-warning icon"
      style="color: red; line-height: 50px"
    ></i>
    <i v-else class="el-icon-upload icon"></i>

    <div class="el-upload__text" style="font-size: 0.8em">
      {{ __("Drop file here or") }}<br /><em
        style="color: var(--theme-color)"
        >{{ __("click to upload") }}</em
      >
    </div>
  </div>
</template>
<script>
const TYPE_WARNING = "warning";
const TYPE_DEFAULT = "default";

export default {
  name: "UploaderInfoIcon",
  props: {
    type: {
      required: false,
      default: TYPE_DEFAULT,
      type: String,
      validator(value) {
        return [TYPE_DEFAULT, TYPE_WARNING].includes(value);
      }
    }
  },
  computed: {
    isWarning() {
      return this.type === TYPE_WARNING;
    }
  }
};
</script>
<style scoped>
.icon {
  font-size: 2em;
  margin-top: 10px;
  margin-bottom: 0;
}
</style>
