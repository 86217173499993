import request, { ac_headers } from "@/utils/studio7ApiService";
import _ from "lodash";

export function requestResponseFromTestURL(requestObject) {
  return request({
    url: "speech/testwebservice",
    method: "post",
    data: requestObject,
    headers: ac_headers()
  });
}

export function evaluateExpression(requestObject) {
  return request({
    url: "speech/evaluate_expression",
    method: "post",
    data: requestObject,
    headers: ac_headers()
  });
}

/**
 * evaluate javascript function using the request
 * @param requestObject
 * @returns {*}
 */
export function evaluateJavascriptFunction(requestObject) {
  return request({
    url: "javascriptfunction/evaluate-javascript-function",
    method: "post",
    data: requestObject,
    headers: ac_headers()
  });
}

export function evaluateJsonPath(requestObject) {
  return request({
    url: "speech/evaluate_jsonpath",
    method: "post",
    data: requestObject,
    headers: ac_headers()
  });
}

export function getAudioPrompt(requestObject) {
  return request({
    url: "speech/get_prompt_audio",
    method: "post",
    data: requestObject,
    headers: ac_headers()
  });
}

export function removeFiles(paths) {
  if (_.isEmpty(paths)) {
    return new Promise(resolve => resolve());
  } else {
    return request({
      url: "services/delete-files",
      method: "post",
      data: { paths },
      headers: ac_headers()
    });
  }
}
export function evaluateAlphaNumeric(requestObject) {
  return request({
    url: "speech/evaluate_alpha_numeric",
    method: "post",
    data: requestObject,
    headers: ac_headers()
  });
}
