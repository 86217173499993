<script>
import { mapActions, mapState } from "vuex";
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";

export default {
  name: "PhoneNumbersManageIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("phonenumbers", {
      contents: state => state.phoneNumbers
    })
  },

  methods: {
    ...mapActions("serviceproviders", {
      fetchAccountsForServiceProvider: "fetchAccountsForServiceProvider",
      fetchTrunksForServiceProvider: "fetchTrunksForServiceProvider"
    }),

    initContent() {
      return {
        phone_number: "",
        sp_id: "",
        trunk_id: "",
        ac_id: "",
        tags: "",
        channels: []
      };
    }
  },
  mounted() {
    this.fetchAccountsForServiceProvider();
    this.fetchTrunksForServiceProvider();
    this.contentType = "Phone Number";
    this.scope = "sp";
    this.primaryKey = "phone_number_id";
  }
};
</script>

<style lang="scss" scoped></style>
