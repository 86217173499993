<template>
  <div v-if="formAction" style="padding: 20px">
    <div class="titles">{{ __("Phone Number") }}</div>
    <div class="details">{{ contentFormInfo.phone_number }}</div>
    <div class="titles">{{ __("Account") }}</div>
    <div class="details">
      {{ phoneNumberAccountOwner }}
    </div>
    <template v-if="!ctEnabled">
      <div class="titles" v-if="contentFormInfo.trunk_name">
        {{ __("Trunk") }}
      </div>
      <div class="details" v-if="contentFormInfo.trunk_name">
        {{ contentFormInfo.trunk_name }}
      </div>
    </template>
    <div class="titles">
      {{ __("Channel") }}
    </div>
    <div class="details">
      <el-tag
        style="padding: 0 3px 3px 3px;margin-left: 3px;"
        v-if="
          contentFormInfo.channel === 'sms_nexmo' ||
            contentFormInfo.channel === 'sms_twilio'
        "
      >
        {{ __("sms") }}
      </el-tag>
      <el-tag style="padding: 0 3px 3px 3px;margin-left: 3px;" v-else>
        {{ contentFormInfo.channel }}
      </el-tag>
    </div>
    <div class="titles" v-if="contentFormInfo.tags">{{ __("Tags") }}</div>
    <div class="details" v-if="contentFormInfo.tags">
      <el-tag
        v-for="tag in contentFormInfo.tags.split(',')"
        :key="tag"
        style="padding: 0 3px 3px 3px;margin-left: 3px;"
      >
        {{ tag }}
      </el-tag>
    </div>
    <el-button
      @click="openModal = true"
      class="editBtn"
      :disabled="!this.canWrite(additionalValidateRoute)"
      >{{ __("Edit") }}</el-button
    >
    <el-button
      @click="handleDelete(contentFormInfo)"
      class="deleteBtn"
      :disabled="!this.canWrite(additionalValidateRoute)"
      >{{ __("Delete") }}
    </el-button>
    <el-dialog
      :visible.sync="openModal"
      v-if="openModal"
      fullscreen
      :show-close="false"
      custom-class="editContentItemModal"
      destroy-on-close
      v-loading="isSubmitting"
    >
      <el-scrollbar :native="false" style="margin-top: 34px">
        <div style="max-height: calc(100vh - 34px);">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
          >
            <el-row type="flex" style="padding-top: 34px">
              <el-col :span="12" :offset="6">
                <page-header :title="__('Phone number')" :contentId="id" />
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item v-if="id === -1" :label="__('Phone numbers')">
                  <el-radio-group v-model="addMethod" class="display-inline">
                    <el-radio label="single">{{
                      __("Add single number")
                    }}</el-radio>
                    <el-radio label="range">{{
                      __("Add number range")
                    }}</el-radio>
                    <el-radio label="copy-paste">
                      {{ __("Copy/paste comma separated list") }}
                    </el-radio>
                    <el-radio label="upload">{{
                      __("Upload CSV file")
                    }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="__('Phone Number')"
                  v-if="addMethod === 'single'"
                  prop="phone_number"
                  :required="addMethod === 'single'"
                >
                  <el-input v-model="contentForm.phone_number"></el-input>
                </el-form-item>
                <el-form-item
                  :label="__('Phone Number Range')"
                  v-else-if="addMethod === 'range'"
                  prop="phone_number_range"
                >
                  <div style="display: flex;">
                    <el-input
                      v-model="rangeFrom"
                      :required="addMethod === 'range'"
                    ></el-input>
                    <div style="width: 50px; padding: 0 5px">
                      {{ __("To") }}
                    </div>
                    <el-input
                      v-model="rangeTo"
                      :required="addMethod === 'range'"
                    ></el-input>
                  </div>
                </el-form-item>
                <el-form-item
                  v-else-if="addMethod === 'copy-paste'"
                  prop="phone_number"
                  :required="addMethod === 'copy-paste'"
                >
                  <el-input
                    type="textarea"
                    :rows="8"
                    :placeholder="__('Please enter comma separated list')"
                    v-model="contentForm.phone_number"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  v-else-if="addMethod === 'upload'"
                  prop="file_path"
                >
                  <csv-uploader
                    :fileUrl="contentForm.file_path"
                    :fileName="file_name"
                    folder="uploads"
                    fit="compact"
                    @on-success="handleSuccess"
                    @on-error="handleError"
                    @on-progress="isLoading = true"
                    @on-delete="handleFileDelete"
                  />
                </el-form-item>
                <el-form-item
                  :label="__('Phone Number')"
                  prop="phone_number"
                  v-else
                >
                  <el-input v-model="contentForm.phone_number"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6"> </el-col>
            </el-row>

            <el-row type="flex" v-if="!ctEnabled">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('SIP Trunk')" prop="trunk_id" required>
                  <el-select
                    filterable
                    v-model="contentForm.trunk_id"
                    default-first-option
                    style="width: 100%"
                  >
                    <el-option
                      v-for="(item, index) in trunks(selectedServiceProviderId)"
                      :key="index"
                      :label="item.label"
                      :value="item.value.trunk_id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Accounts')" prop="ac_id">
                  <el-select
                    value-key="ac_id"
                    filterable
                    v-model="contentForm.ac_id"
                    placeholder="Select"
                    default-first-option
                    style="width: 100%"
                    clearable
                  >
                    <el-option
                      v-for="(item, index) in accounts(
                        selectedServiceProviderId
                      )"
                      :key="index"
                      :label="item.label"
                      :value="item.value.ac_id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item class="form_label_top" :label="__('Tags')">
                  <el-tag
                    :key="tag"
                    v-for="tag in getTagsArray(contentForm.tags)"
                    closable
                    :disable-transitions="false"
                    @close="handleDeleteTag(tag)"
                  >
                    {{ tag }}
                  </el-tag>
                  <el-input
                    class="input-new-tag"
                    v-if="inputVisible"
                    v-model="inputValue"
                    ref="saveTagInput"
                    @keyup.enter.native="handleInputConfirm"
                    @blur="handleInputConfirm"
                  >
                  </el-input>
                  <el-button
                    v-else
                    class="button-new-tag"
                    @click="showInput"
                    size="small"
                    id="add_tag_btn"
                  >
                    {{ __("+ New Tag") }}</el-button
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div
            slot="footer"
            style="display: flex;margin-left: 25%;margin-bottom: 20px"
          >
            <save-button
              :disabled="!this.canWrite(additionalValidateRoute)"
              type="primary"
              @click="submitForm"
              class="submitBtn"
              :primaryKey="id"
            />
            <el-button @click="handleCancelBtn" class="cancelBtn">{{
              __("Cancel")
            }}</el-button>
          </div>
        </div>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import PageHeader from "@/components/PageHeader";
import { mapActions, mapState, mapGetters } from "vuex";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import _ from "lodash";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import CsvUploader from "@/components/uploaders/types/CsvUploader";
import { removeFiles } from "@/api/services";
import SaveButton from "@/components/SaveButton";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  components: {
    PageHeader,
    CsvUploader,
    SaveButton
  },
  data() {
    const validatePhoneNumber = (rule, value, callback) => {
      if (this.addMethod === "single") {
        if (!this.contentForm.phone_number.length) {
          callback(__("phone number is required"));
        } else {
          if (!/^\+?\d*$/.test(this.contentForm.phone_number)) {
            callback(__("phone number is invalid"));
          } else {
            callback();
          }
        }
      } else if (this.addMethod === "copy-paste") {
        if (!this.contentForm.phone_number.length) {
          callback(__("comma separated numbers are required"));
        } else {
          if (!/^\+?\d+(?:,\+?\d+)*$/.test(this.contentForm.phone_number)) {
            callback(__("comma separated numbers are invalid"));
          } else {
            callback();
          }
        }
      } else {
        callback();
      }
    };
    const validateRange = (rule, value, callback) => {
      if (this.addMethod === "range") {
        if (!this.rangeFrom.length) {
          callback(__("range starting phone number is required"));
        } else if (!this.rangeTo.length) {
          callback(__("range ending phone number is required"));
        } else if (!/^\+?\d*$/.test(this.rangeFrom)) {
          callback(__("range starting phone number is invalid"));
        } else if (!/^\+?\d*$/.test(this.rangeTo)) {
          callback(__("range ending phone number is invalid"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    const validateTrunk = (rule, value, callback) => {
      if (!value && !this.ctEnabled) {
        callback(__("Trunk is required"));
      } else {
        callback();
      }
    };

    return {
      rules: {
        phone_number: [
          {
            required: true,
            trigger: "blur",
            validator: validatePhoneNumber
          }
        ],
        phone_number_range: [
          {
            required: true,
            trigger: "blur",
            validator: validateRange
          }
        ],
        trunk_id: [
          {
            trigger: "blur",
            validator: validateTrunk
          }
        ]
      },
      additionalValidateRoute: "phone-numbers",
      addMethod: "single",
      rangeFrom: "",
      rangeTo: "",
      file_name: "",
      uploadedFilePaths: [],
      isLoading: false, // to disable submit button while the upload is in progress
      isSubmitting: false,
      inputVisible: false,
      inputValue: ""
    };
  },

  computed: {
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId
    }),

    ...mapGetters("serviceproviders", {
      accounts: "accounts",
      trunks: "trunks"
    }),
    ...mapGetters("app", {
      ctEnabled: "ctEnabled"
    }),

    /**
     * Computed property for the account mapped for the selected phone number
     */
    phoneNumberAccountOwner: function() {
      return this.contentFormInfo.ac_name
        ? this.contentFormInfo.ac_name
        : __("Not Mapped");
    }
  },

  methods: {
    ...mapActions("phonenumbers", {
      createPhoneNumber: "createPhoneNumber",
      updatePhoneNumber: "updatePhoneNumber",
      deleteContentMethod: "deletePhoneNumber",
      undoDeleteContent: "undoDeletePhoneNumber"
    }),
    getTagsArray(tags) {
      return tags ? tags.split(",") : [];
    },
    handleCancelBtn() {
      this.addMethod = "single";
      this.resetNumRangeContent();
      this.resetFileFormContent();
      this.handleCancel();
    },
    handleDeleteTag(tag) {
      let tagsArray = _.split(this.contentForm.tags, ",");
      const tagIndex = _.findIndex(tagsArray, tagA => {
        return tagA === tag;
      });
      tagsArray.splice(tagIndex, 1);
      this.contentForm.tags = tagsArray.join(",");
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        if (this.contentForm.tags) {
          this.contentForm.tags += "," + inputValue;
        } else {
          this.contentForm.tags += inputValue;
        }
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    handleError(err) {
      console.log(err);
      this.isLoading = false;
    },
    handleSuccess(url) {
      this.isLoading = false;
      this.uploadedFilePaths.push(url.path);
      this.contentForm.file_path = url.path;
      this.file_name = url.uploaded_file_name;
    },
    handleFileDelete() {
      this.resetFileFormContent();
    },
    resetFileFormContent() {
      this.contentForm.file_path = "";
      this.file_name = "";
    },
    resetNumRangeContent() {
      this.rangeFrom = "";
      this.rangeTo = "";
    },
    removeUploadedFiles() {
      removeFiles(this.uploadedFilePaths);
    },
    permit(access_level) {
      let additional_path = "phone_numbers";
      let base_path = _.replace(this.$route.path, /\//g, ".")
        .slice(1)
        .toString();
      base_path += additional_path;
      return this.can(`${base_path}.${access_level}`);
    },

    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true;
          const process =
            this.id === -1 ? this.createPhoneNumber : this.updatePhoneNumber;

          this.contentForm.sp_id = this.selectedServiceProviderId;
          this.contentForm.channel = "voice";
          if (this.addMethod === "range") {
            this.contentForm.phone_number = this.rangeFrom + "-" + this.rangeTo;
          } else if (this.addMethod === "upload") {
            this.contentForm.phone_number = this.contentForm.file_path;
          }
          process(this.contentForm)
            .then(data => {
              this.isSubmitting = false;
              if (this.addMethod !== "single") {
                this.addMethod = "single";
                this.resetNumRangeContent();
                this.resetFileFormContent();
              }
              this.id === -1
                ? this.$message({
                    name: "success",
                    message: data.message
                  })
                : this.$message({
                    type: "success",
                    message: __("Phone Number updated successfully")
                  });

              EventBus.$emit("list-changed", data.data);
              this.handleCancel();
            })
            .catch(err => {
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.response.data.message
              });
            });
        }
      });
    },
    beforeDestroy() {
      this.removeUploadedFiles();
    }
  },
  watch: {
    addMethod: {
      deep: true,
      immediate: true,
      handler: function(method) {
        // Reset the uploaded file url field if method is not upload
        if (method != "upload") {
          this.resetFileFormContent();
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";
@import "~@/styles/node_common.scss";

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }
}
</style>
