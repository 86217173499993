<script>
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
import path from "path";
import { appendSector } from "@/utils/url";

export default {
  props: {
    fileUrl: {
      required: false,
      type: String
    },
    folder: {
      required: false,
      type: String
    },
    allowTypes: {
      required: false,
      type: Array,
      default: () => []
    },
    maxSizeInMb: {
      required: false,
      type: Number,
      default: 2
    },
    encryptContents: {
      required: false,
      type: Boolean,
      default: false
    },
    fileList: {
      required: false,
      type: Array
    },
    fit: {
      required: false,
      type: String,
      default: "cover"
    },
    convertAudio: {
      required: false,
      type: Boolean,
      default: true
    },
    removeUniquenessPrefixFromName: {
      required: false,
      type: Boolean,
      default: true
    },
    showFileName: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isLoading: false,
      fileName$: "",
      codesToMIME: {
        txt: ["text/plain"],
        pdf: ["application/pdf"],
        jpg: ["image/jpeg"],
        png: ["image/png"],
        csv: [
          "application/vnd.ms-excel",
          "text/csv",
          "text/x-csv",
          "application/csv",
          "application/x-csv",
          "text/comma-separated-values",
          "text/x-comma-separated-values"
        ],
        wav: ["audio/wav", "audio/x-wav"],
        mp3: ["audio/mpeg", "audio/mp3"],
        mp4: ["video/mp4"],
        mov: ["video/quicktime"],
        wmv: ["video/x-ms-wmv"],
        avi: ["video/x-msvideo"],
        json: ["application/json"],
        secret: ["cert", "key", "pem", "crt", "csr", "p12"],
        pem: ["pem"],
        p12: ["p12"],
        crt: ["crt"],
        csr: ["csr"],
        key: ["key"]
      }
    };
  },
  computed: {
    ...mapGetters("app", {
      selectedServiceProviderName: "selectedServiceProviderName"
    }),
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId,
      token: state => state.token
    }),

    allowedFileTypes() {
      return _.filter(this.codesToMIME, (values, key) =>
        _.includes(this.allowTypes, key)
      );
    },

    allowedTypesReadable() {
      return this.allowTypes.join(", ");
    },

    getUploadUrl() {
      return appendSector(
        window.location.hostname,
        process.env.VUE_APP_BACKEND_BASE_API,
        "/services/upload"
      );
    },
    getUploadCTFUrl() {
      return appendSector(
        window.location.hostname,
        process.env.VUE_APP_BACKEND_BASE_API,
        "/studio/decode-ctf"
      );
    },
    headers() {
      return {
        Authorization: "Bearer " + this.token
      };
    },
    AcHeaders() {
      return {
        Authorization: "Bearer " + this.token,
        scope: "ac",
        "scope-id": this.selectedAccountId
      };
    },
    formData() {
      let formData = {};
      let folder =
        `sp_${this.selectedServiceProviderId}/ac_${this.selectedAccountId}` +
        "/";
      if (this.folder) {
        folder += this.folder;
      }
      if (folder && folder.slice(-1) === "/") {
        folder = folder.slice(0, -1);
      }

      // create a folder based on the current timestamp
      folder += "/" + Date.now();

      Object.assign(formData, {
        folder,
        encryptContents: this.encryptContents ? 1 : 0,
        convertAudio: this.convertAudio ? 1 : 0
      });
      return formData;
    }
  },
  methods: {
    getFileExtension(fileName) {
      return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
    },

    handleSuccess(res) {
      this.isLoading = false;
      this.$emit("on-success", res);
    },
    handleError(err) {
      this.isLoading = false;
      this.$emit("on-error", err);
    },
    handleUploadProgress() {
      this.isLoading = true;
    },

    getName(p) {
      return path.basename(p);
    },

    getTailName(p) {
      return (
        _(_.split(this.getName(p), "_"))
          .tail()
          .value()
          .join("_") || this.getName(p)
      );
    },

    isFileAllowed(file) {
      if (_.isEmpty(this.allowTypes)) return true;

      return _.some(
        _.flatten(this.allowedFileTypes),
        type =>
          file &&
          ((file.type && type === file.type) ||
            (file.name && type === this.getFileExtension(file.name)))
      );
    },

    beforeFileUpload(file) {
      if (!this.isFileAllowed(file)) {
        this.$message({
          message: __("Only these files are allowed: :allowed", {
            allowed: this.allowedTypesReadable
          }),
          type: "error"
        });
        return false;
      }

      let isFileSizeBelowMaxSize =
        file && file.size && file.size / 1024 / 1024 < this.maxSizeInMb;

      if (!isFileSizeBelowMaxSize) {
        this.$message({
          message: __("file size exceeded :max :unit", {
            max: this.maxSizeInMb,
            unit: "MB"
          }),
          type: "error"
        });

        return false;
      }

      return true;
    }
  },
  watch: {
    fileUrl: {
      immediate: true,
      handler(val) {
        if (val && this.showFileName && !this.fileName) {
          this.fileName$ = this.removeUniquenessPrefixFromName
            ? this.getTailName(val)
            : this.getName(val);
        }
      }
    },
    fileName: {
      immediate: true,
      handler(val) {
        if (val && this.showFileName) {
          this.fileName$ = val;
        }
      }
    }
  }
};
</script>
